export const FETCH_FACEBOOK_PAGE_DATA = 'FETCH_FACEBOOK_PAGE_DATA';
export const REMOVE_FACEBOOK_PAGE_DATA = 'REMOVE_FACEBOOK_PAGE_DATA';
export const FETCH_FACEBOOK_PAGE_DATA_FAILED = 'FETCH_FACEBOOK_PAGE_DATA_FAILED';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_START_INDEX = 'SET_START_INDEX';
export const SET_END_INDEX = 'SET_END_INDEX';
export const SET_HAS_MORE = 'SET_HAS_MORE';
export const SET_LOADING = 'SET_LOADING';
export const SET_DATA_LENGTH = 'SET_DATA_LENGTH';
export const SET_ITEM_TYPE = 'SET_ITEM_TYPE';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const REMOVE_CATEGORIES = 'REMOVE_CATEGORIES';
export const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';

export const FETCH_PRICE_LIST_DATA = 'FETCH_PRICE_LIST_DATA';
export const SET_TOP_OFFER_ITEM_IDS = 'SET_TOP_OFFER_ITEM_IDS';
export const FETCH_TOP_OFFER_DATA = 'FETCH_TOP_OFFER_DATA';
export const ADD_FETCH_PRICE_LIST_DATA = 'ADD_FETCH_PRICE_LIST_DATA';
export const REMOVE_PRICE_LIST_DATA = 'REMOVE_PRICE_LIST_DATA';
export const FETCH_PRICE_LIST_DATA_FAILED = 'FETCH_PRICE_LIST_DATA_FAILED';
