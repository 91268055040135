import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './style/main.scss';

import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import facebookPageDataReducer from './reducers/facebookPageData';
import priceListDataReducer from './reducers/priceListData';
import languageReducer from './reducers/language';
import thunk from 'redux-thunk';

import App from './App';
import * as serviceWorker from './serviceWorker';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
    facebookPageData: facebookPageDataReducer,
    priceListData: priceListDataReducer,
    language: languageReducer,
});

const store = createStore(rootReducer,  composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
