import * as poc from "../constants/peaceOfContent";
import * as tournamentContent from '../content/tournamentHistoryContent';
import * as aboutUsContent from '../content/aboutUsContent';
import * as footerContent from '../content/footerContent';
import * as pizzeriaContent from '../content/pizzeriaContent';
import * as benefitsWidget from './widgets/benefitsWidgetContent';
import * as spotlightWidgetContent from './widgets/spotlightWidgetContetn';

export const getContent = (language, peaceOfContent) => {

    // german language (secondary site language)
    if (language === 'de') {
        switch (peaceOfContent) {
            // home
            case poc.HOME_HEAD:
                return 'Home';
            case poc.HOME_POSTS:
                return'Beiträge';
            case poc.HOME_SEE_ALL_POSTS:
                return 'Alle Beiträge >>';
            case poc.BENEFITS_WIDGET:
                return benefitsWidget.getBenefitsContentDe();
            case poc.SPOTLIGHT_WIDGET:
                return spotlightWidgetContent.getSpotlightContentDe();
            case poc.TOP_OFFER:
                return 'Top Angebote';
            // content de
            case poc.ABOUT_US_HEAD:
                return 'Über uns';
            case poc.ABOUT_US_TITLE:
                return aboutUsContent.getAboutUsTitleDe();
            case poc.ABOUT_US:
                return aboutUsContent.getAboutUsContentDe();
            // pizzeria
            case poc.PIZZERIA_HEAD:
                return 'Pizzeria';
            case poc.PIZZERIA_TITLE:
                return `Pizzeria`;
            case poc.PIZZERIA_OFFER:
                return pizzeriaContent.getPizzeriaContentOfferDe();
            case poc.MENU:
                return `Menü`;
            case poc.DRINKS:
                return `Getränke`;
            case poc.SEARCH_LABEL_FOR_MENU:
                return `Menü durchsuchen`;
            case poc.SEARCH_LABEL_FOR_DRINKS:
                return `Getränke durchsuchen`;
            case poc.SEARCH_RESULTS_FOR_MENU:
                return `Such Ergebnisse vom Menü für`;
            case poc.SEARCH_RESULTS_FOR_DRINKS:
                return `Such Ergebnisse von Getränkeen für`;
            case poc.SEARCH_RESULTS_FOR_PIZZERIA:
                return `Such Ergebnisse für`;
            case poc.ITEM_PRICE:
                return `Preis`;
            case poc.ITEM_OPTIONS:
                return `Optionen`;
            case poc.SMALL:
                return `Kleine`;
            case poc.BIG:
                return `Große`;
            case poc.JUMBO:
                return `Jumbo`;
            case poc.ITEM_CONTAINS:
                return 'Enthält';
            case poc.YOU_MIGHT_ALSO_LIKE:
                return 'Villeicht könnte Ihnen das gefallen';
            // image gallery
            case poc.IMAGE_GALLERY_HEAD:
                return 'Bildergalerie';
            case poc.IMAGE_GALLERY_TITLE:
                return 'Bildergalerie';
            // tournament
            case poc.TOURNAMENT_HISTORY_HEAD:
                return 'Turniergeschichte';
            case poc.TOURNAMENT_HISTORY_TITLE:
                return tournamentContent.getTournamentHistoryTitleDe();
            case poc.TOURNAMENT_HISTORY:
                return tournamentContent.getTournamentHistoryContentDe();
            // navigation de
            case poc.NAV_TITLE:
                return 'Hauptmenü';
            case poc.NAV_HOME:
                return `Home`;
            case poc.NAV_ABOUT_US:
                return `Über uns`;
            case poc.NAV_PIZZERIA:
                return `Pizzeria`;
            case poc.NAV_IMAGE_GALLERY:
                return `Bildergalerie`;
            case poc.NAV_TOURNAMENT_HISTORY:
                return `Turniergeschichte`;
            // footer content
            case poc.FOOTER_CONTENT_TITLE:
                return footerContent.getFooterContentTitleDe();
            case poc.FOOTER_CONTENT_WORKING_HOURS:
                return footerContent.getFooterContentWorkingHoursDe();
            case poc.FOOTER_CONTENT_ADDRESS:
                return footerContent.getFooterContentAddress();
            case poc.FOOTER_CONTENT_CONTACT:
                return footerContent.getFooterContentContact();
            case poc.FOOTER_CONTENT_SOCIAL_MEDIA:
                return footerContent.getFooterContentSocialMediaDe();
            default:
                return '';
        }
    }
    // croatian language (main site language)
    else {
        switch (peaceOfContent) {
            // content hr
            // home
            case poc.HOME_HEAD:
                return 'Početna';
            case poc.HOME_POSTS:
                return 'Objave';
            case poc.HOME_SEE_ALL_POSTS:
                return 'Sve objave >>';
            case poc.BENEFITS_WIDGET:
                return benefitsWidget.getBenefitsContentHr();
            case poc.SPOTLIGHT_WIDGET:
                return spotlightWidgetContent.getSpotlightContentHr();
            case poc.TOP_OFFER:
                return 'Top ponude';
            // about us
            case poc.ABOUT_US_HEAD:
                return 'O nama';
            case poc.ABOUT_US_TITLE:
                return aboutUsContent.getAboutUsTitleHr();
            case poc.ABOUT_US:
                return aboutUsContent.getAboutUsContentHr();
            // pizzeria
            case poc.PIZZERIA_HEAD:
                return 'Pizzeria';
            case poc.PIZZERIA_TITLE:
                return `Pizzeria`;
            case poc.PIZZERIA_OFFER:
                return pizzeriaContent.getPizzeriaContentOfferHr();
            case poc.MENU:
                return `Jelovnik`;
            case poc.DRINKS:
                return `Cjenik pića`;
            case poc.SEARCH_LABEL_FOR_MENU:
                return `Pretraga jelovnika`;
            case poc.SEARCH_LABEL_FOR_DRINKS:
                return `Pretraga pića`;
            case poc.SEARCH_RESULTS_FOR_MENU:
                return `Rezultati pretrage jelovnika za`;
            case poc.SEARCH_RESULTS_FOR_DRINKS:
                return `Rezultati pretrage pića za`;
            case poc.SEARCH_RESULTS_FOR_PIZZERIA:
                return `Rezultati pretrage za`;
            case poc.ITEM_PRICE:
                return `Cijena`;
            case poc.ITEM_OPTIONS:
                return `Opcije`;
            case poc.SMALL:
                return `Mala`;
            case poc.BIG:
                return `Velika`;
            case poc.JUMBO:
                return `Jumbo`;
            case poc.ITEM_CONTAINS:
                return `Sadrži`;
            case poc.YOU_MIGHT_ALSO_LIKE:
                return 'Možda će se Vam i ovo svidjeti';
            // image gallery
            case poc.IMAGE_GALLERY_HEAD:
                return 'Galerija slika';
            case poc.IMAGE_GALLERY_TITLE:
                return 'Galerija slika';
            // tournament
            case poc.TOURNAMENT_HISTORY_HEAD:
                return 'Povijest turnira';
            case poc.TOURNAMENT_HISTORY_TITLE:
                return tournamentContent.getTournamentHistoryTitleHr();
            case poc.TOURNAMENT_HISTORY:
                return tournamentContent.getTournamentHistoryContentHr();
            // navigation hr
            case poc.NAV_TITLE:
                return 'Glavni izbornik';
            case poc.NAV_HOME:
                return `Početna`;
            case poc.NAV_ABOUT_US:
                return `O nama`;
            case poc.NAV_PIZZERIA:
                return `Pizzeria`;
            case poc.NAV_IMAGE_GALLERY:
                return `Galerija slika`;
            case poc.NAV_TOURNAMENT_HISTORY:
                return `Povijest turnira`;
            // footer content
            case poc.FOOTER_CONTENT_TITLE:
                return footerContent.getFooterContentTitleHr();
            case poc.FOOTER_CONTENT_WORKING_HOURS:
                return footerContent.getFooterContentWorkingHoursHr();
            case poc.FOOTER_CONTENT_ADDRESS:
                return footerContent.getFooterContentAddress();
            case poc.FOOTER_CONTENT_CONTACT:
                return footerContent.getFooterContentContact();
            case poc.FOOTER_CONTENT_SOCIAL_MEDIA:
                return footerContent.getFooterContentSocialMediaHr();
            default:
                return '';
        }
    }
};