import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {Button, Icon, Menu, Drawer} from "antd";
import {getContent} from '../../content/contentBasedOnLanguage'
import {connect} from "react-redux";
import * as poc from '../../constants/peaceOfContent'
import logo from '../../logo/venera-logo.png'
import LanguageChooser from "./LanguageChooser";

export class Navigation extends PureComponent {

    state = {
        current: '',
        visible: false
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    handleClick = e => {
        this.setState({
            current: e.key,
            visible: false
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.setState({
                visible: false,
            });
        }
    }

    render() {
        const { history } = this.props;
        const location = this.state.current === '' ? history.location.pathname : this.state.current;

        return (
            <nav className="menuBar">
                <div className="logo">
                    <img className="responsive" src={logo} alt="logo"/>
                    <NavLink to="/"/>
                </div>
                <div className="menuCon">
                    <div className="leftMenu">
                        <Menu onClick={this.handleClick} selectedKeys={[location]} mode="horizontal" theme="dark" style={{ lineHeight: '64px' }}>
                            <Menu.Item key="/">
                                <Icon type="home" />
                                {getContent(this.props.language, poc.NAV_HOME)}
                                <NavLink to="/"/>
                            </Menu.Item>
                            <Menu.Item key="/about">
                                <Icon type="info" />
                                {getContent(this.props.language, poc.NAV_ABOUT_US)}
                                <NavLink to="/about"/>
                            </Menu.Item>
                            <Menu.Item key="/pizzeria">
                                <Icon type="unordered-list" />
                                {getContent(this.props.language, poc.NAV_PIZZERIA)}
                                <NavLink to="/pizzeria"/>
                            </Menu.Item>
                            <Menu.Item key="/image-gallery">
                                <Icon type="picture" />
                                {getContent(this.props.language, poc.NAV_IMAGE_GALLERY)}
                                <NavLink to="/image-gallery"/>
                            </Menu.Item>
                            <Menu.Item key="/tournament-history">
                                <Icon type="history" />
                                {getContent(this.props.language, poc.NAV_TOURNAMENT_HISTORY)}
                                <NavLink to="/tournament-history"/>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <div className="rightMenu">
                        <LanguageChooser/>
                    </div>

                    <div className={"drawer-button-wrapper"}>
                        <Button className="barsMenu" type="primary" shape="round" icon="menu" onClick={this.showDrawer}/>
                    </div>
                    <Drawer
                        title={getContent(this.props.language, poc.NAV_TITLE)}
                        placement="right"
                        onClose={this.onClose}
                        visible={this.state.visible}
                    >
                        <Menu mode="inline" onClick={this.handleClick} selectedKeys={[location]} theme="light" style={{ lineHeight: '64px' }}>
                            <Menu.Item key="/">
                                <Icon type="home" />
                                {getContent(this.props.language, poc.NAV_HOME)}
                                <NavLink to="/"/>
                            </Menu.Item>
                            <Menu.Item key="/about">
                                <Icon type="info" />
                                {getContent(this.props.language, poc.NAV_ABOUT_US)}
                                <NavLink to="/about"/>
                            </Menu.Item>
                            <Menu.Item key="/pizzeria">
                                <Icon type="unordered-list" />
                                {getContent(this.props.language, poc.NAV_PIZZERIA)}
                                <NavLink to="/pizzeria"/>
                            </Menu.Item>
                            <Menu.Item key="/image-gallery">
                                <Icon type="picture" />
                                {getContent(this.props.language, poc.NAV_IMAGE_GALLERY)}
                                <NavLink to="/image-gallery"/>
                            </Menu.Item>
                            <Menu.Item key="/tournament-history">
                                <Icon type="history" />
                                {getContent(this.props.language, poc.NAV_TOURNAMENT_HISTORY)}
                                <NavLink to="/tournament-history"/>
                            </Menu.Item>
                        </Menu>
                        <LanguageChooser/>
                    </Drawer>
                </div>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language
    };
}

export default connect(
    mapStateToProps,
    null
)(withRouter(Navigation));