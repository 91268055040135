import React from 'react';
import { Timeline } from 'antd';

export const getTournamentHistoryTitleHr = () => {
    return (<h1 className="title">Tradicionalni memorijalni noćni malonogometni turnir <b>"Venera"</b> Sveti Juraj na Bregu</h1>);
};

export const getTournamentHistoryTitleDe = () => {
    return (<h1 className="title">Das Traditionelle Denkmal an das kleine Nachtfußballturnier <b>"Venera"</b> Sveti Juraj na Bregu</h1>);
};

export const getTournamentHistoryContentHr = () => {
    return(
        <Timeline mode="alternate">
            {/*venera 08*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 08"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 20 seniorskih ekipa</h3>
                <p>1.Caffe bar Arcus</p>
                <p>2."Start Auto škola" Mala Subotica</p>
                <p>3.Onix bar Mursko Središće</p>
                <p>4.Crvena ruža Bednja</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 6 veteranskih ekipa</h3>
                <p>1.Bratić-Viher-Vidra</p>
                <p>2.Elita Merkur</p>
                <p>3.Beauty centar Ljupka</p>
                <p>4.SA-RA Pribislavec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 8 pionirskih ekipa</h3>
                <p>1.Bratić-Viher-Vidra</p>
                <p>2.Dream team</p>
                <p>3.C-Lab</p>
                <p>4.Vatreni</p>
            </Timeline.Item>
            {/*venera 07*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 07"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 24 seniorskih ekipa</h3>
                <p>1.Žulić graditeljstvo Domino bar</p>
                <p>2.Caffe bar Arcus</p>
                <p>3.Bar Torino Maribor</p>
                <p>4.Crvena ruža Bednja</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 8 veteranskih ekipa</h3>
                <p>1.Proventus</p>
                <p>2.Kos transporti</p>
                <p>3.Međimurje PMP</p>
                <p>4.Caffe bar Picadili</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 8 pionirskih ekipa</h3>
                <p>1.Proventus</p>
                <p>2.Nk Međimurje</p>
                <p>3.Nk Međimurec</p>
                <p>4.Crveni Vragovi</p>
            </Timeline.Item>
            {/*venera 06*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 06"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 25 seniorskih ekipa</h3>
                <p>1.Proming</p>
                <p>2.Eurotransporti Kostanjevec</p>
                <p>3.Onix Bar</p>
                <p>4.Crvena ruža Bednja</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 8 veteranskih ekipa</h3>
                <p>1.Međimurje PMP	</p>
                <p>2.Limarija Vinković</p>
                <p>3."SA -RA" Pribislavec</p>
                <p>4.Elita Merkur</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 16 pionirskih ekipa</h3>
                <p>1.Vihor Strahoninec</p>
                <p>2.Loka Goričan</p>
                <p>3.Pokemoni Strahoninec</p>
                <p>4.Vulička</p>
            </Timeline.Item>
            {/*venera 05*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 05"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 29 seniorskih ekipa</h3>
                <p>1."Bar Torino" Maribor"</p>
                <p>2.Pilana Posavec Lopatinec</p>
                <p>3."Start Auto škola" Mala Subotica</p>
                <p>4.Knegra</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 8 veteranskih ekipa</h3>
                <p>1."SA -RA" Pribislavec</p>
                <p>2."Međimurje PMP"</p>
                <p>3.Caffe Bar Palada Varaždin</p>
                <p>4.Vodači Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 16 pionirskih ekipa</h3>
                <p>1.Mega - Ing Čakovec</p>
                <p>2.Nk Sloga Čakovec</p>
                <p>3.Pokemoni Strahoninec</p>
                <p>4.Nk Zasadbreg</p>
            </Timeline.Item>
            {/*venera 04*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 04"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 26 seniorskih ekipa</h3>
                <p>1.Bar "Torino" Maribor</p>
                <p>2."Eurotransporti Kostanjevec"</p>
                <p>3."Start Auto škola" Mala Subotica</p>
                <p>4."Autoimpex" Pribislavec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 13 veteranskih ekipa</h3>
                <p>1."SA -RA" Pribislavec</p>
                <p>2."Međimurje PMP"</p>
                <p>3.Bar "Torino" Maribor</p>
                <p>4."Zalogajnica Šanjek" Varaždin</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 18 pionirskih ekipa</h3>
                <p>1."Proventus Varteks" Varaždin</p>
                <p>2."Cafe bar Centar" Gornji Mihaljevec</p>
                <p>3."Automikulaj" Krištanovec</p>
                <p>4."Krojači" Varaždin</p>
            </Timeline.Item>
            {/*venera 03*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 03"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 26 seniorskih ekipa</h3>
                <p>1.Katex & biro plus</p>
                <p>2.Knjigovodstvo Cimerman</p>
                <p>3.Promming</p>
                <p>4.Toyota Šavora</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 16 veteranskih ekipa</h3>
                <p>1.Zalogajnica Šanjek</p>
                <p>2.Radnička ulica</p>
                <p>3.Elita Merkur osiguranje</p>
                <p>4.Međimurje Pmp</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 13 pionirskih ekipa</h3>
                <p>1.Tom transporti</p>
                <p>2.Centar Vinković</p>
                <p>3.Nk Krištanovec</p>
                <p>4.Nk Venera</p>
            </Timeline.Item>
            {/*venera 02*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 02"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 26 seniorskih muških ekipa</h3>
                <p>1.Centrometal Macinec</p>
                <p>2.Auto škola Start Mala Subotica</p>
                <p>3.Katex and Plus Čakovec</p>
                <p>4.KMB Uno Marketing Ludbreg</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 10 veteranskih muških ekipa</h3>
                <p>1.Elita Merkur Čakovec</p>
                <p>2.Caffe bar M Varaždin</p>
                <p>3.Radnička ulica Lopatinec</p>
                <p>4.Udruga trgovca Međimurja Strahoninec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 2 ženskih ekipa</h3>
                <p>1.ŽNK Mali Mihaljevec</p>
                <p>2.Fotex Nedeljanec</p>
            </Timeline.Item>
            {/*venera 01*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 01"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 22 seniorskih muških ekipa</h3>
                <p>1.Unipot Fulda Čakovec</p>
                <p>2.Fotex Nedeljanec</p>
                <p>3.Euroherc Najmarket Čakovec</p>
                <p>4.Patafta i sinovi Strahoninec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 8 veteranskih muških ekipa</h3>
                <p>1.Dib Međimurka Čakovec</p>
                <p>2.Elita Merkur Čakovec</p>
                <p>3.Fotex Nedeljanec</p>
                <p>4.Nk Strahoninec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 8 ženskih ekipa</h3>
                <p>1.Fotex Nedeljanec</p>
                <p>2.Bad Blue Girls Koprivnica</p>
                <p>3.Mali Mihaljevec</p>
                <p>4.Lovorke Cirkovljan</p>
            </Timeline.Item>
            {/*venera 00*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 00"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 22 seniorskih muških ekipa</h3>
                <p>1.San Marco Čakovec</p>
                <p>2.Auto škola Start Mala Subotica</p>
                <p>3.Unipot</p>
                <p>4.Granobel</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 10 veteranskih muških ekipa</h3>
                <p>1.Tif</p>
                <p>2.Korni</p>
                <p>3.Radnička ulica Lopatinec</p>
                <p>4.Iver Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 5 ženskih ekipa</h3>
                <p>1.Fotex Nedeljanec</p>
                <p>2.Tigrice Štrigova</p>
                <p>3.Mali Mihaljevec</p>
                <p>4.Oaza Mladost Selnica</p>
            </Timeline.Item>
            {/*venera 99*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 99"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 24 seniorskih muških ekipa</h3>
                <p>1.Orkan Gradvez Ljubljana</p>
                <p>2.Royal Barflay Čakovec</p>
                <p>3.Euro Unit Slakovec</p>
                <p>4.Motoreni Raidersi Nedelišće</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 12 veteranskih muških ekipa</h3>
                <p>1.Panex servis Čakovec</p>
                <p>2.IPC Čakovec</p>
                <p>3.Rdnička ulica Lopatinec</p>
                <p>4.Pedagoška Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 9 ženskih ekipa</h3>
                <p>1.Fotex Nedeljanec</p>
                <p>2.Mali Mihaljevec</p>
                <p>3.Pande Mursko Središće</p>
                <p>4.Vidovčice Donji vidovec</p>
            </Timeline.Item>
            {/*venera 98*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 98"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 26 seniorskih muških ekipa</h3>
                <p>1.Adrenalin Ljubljana</p>
                <p>2.Moharić Comerce</p>
                <p>3.Bufet Zagorska klet trsek Varaždin</p>
                <p>4.Auto škola Start Mala Subotica</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 12 veteranskih muških ekipa</h3>
                <p>1.Venera Sveti Juraj na Bregu</p>
                <p>2.Napredak Gornji Kraljevec</p>
                <p>3.Auto škola Start Mala Subotica</p>
                <p>4.Elektrocentar Korunić Šenkovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 8 ženskih ekipa</h3>
                <p>1.Crtorad Čakovec</p>
                <p>2.Tigrice Štrigova</p>
                <p>3.Fotex Nedeljanec</p>
                <p>4.Lovorke Cirkovljan</p>
            </Timeline.Item>
            {/*venera 97*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 97"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 25 seniorskih muških ekipa</h3>
                <p>1.Elektrocentar Korunić Šenkovec</p>
                <p>2.Seoski turizam Veselko Vugrišinec</p>
                <p>3.Radnička ulica Lopatinec</p>
                <p>4.Reidersi Nedelišće</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 8 veteranskih muških ekipa</h3>
                <p>1.Venera Sveti Juraj na Bregu</p>
                <p>2.Iver Čakovec</p>
                <p>3.GMC Smrtić Nedelišće</p>
                <p>4.Auto škola Start Mala Subotica</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 8 ženskih ekipa</h3>
                <p>1.Tigrice Štrigova</p>
                <p>2.Fotex Nedeljanec</p>
                <p>3.Lovorke Cirkovljan</p>
                <p>4.Jelen Čakovec</p>
            </Timeline.Item>
            {/*venera 96*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 96"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 24 seniorskih muških ekipa</h3>
                <p>1.Bufet Zagorska klet trsek Varaždin</p>
                <p>2.Klesarska radnja Bratković Mihovljan</p>
                <p>3.Bistro ABI Zasadbreg</p>
                <p>4.Potrti kotač Jurovčak</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 7 veteranskih muških ekipa</h3>
                <p>1.Tesarska radnja Posavec Lopatinec</p>
                <p>2.Venera Sveti Juraj na Bregu</p>
                <p>3.Pedagoška Čakovec</p>
                <p>4.Iver Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovalo 6 ženskih ekipa</h3>
                <p>1.Radio Venera Sveti Juraj na Bregu	</p>
                <p>2.Market Iva Vučetinec</p>
                <p>3.Bistro ABI Zasadbreg</p>
                <p>4.Omladinac Novo selo Rok</p>
            </Timeline.Item>
            {/*venera 95*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 95"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Sudjelovalo 28 seniorskih muških ekipa	</h3>
                <p>1.Sneck bar Martin Nedelišće</p>
                <p>2.Bufet Zagorska klet trsek Varaždin</p>
                <p>3.Potrti kotač Jurovčak</p>
                <p>4.Reidersi Nedelišće</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <p>Odigrana je revijalna malonogometna utakmica žena u kojoj su se sastale Frizerski salon Anđela Pleškovec i Trgovina Jambrović Lopatinec</p>
            </Timeline.Item>
            {/*venera 94*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 94"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Sudjelovala 21 seniorska muška ekipa</h3>
                <p>1.Bistro Jocker Mursko Središće</p>
                <p>2.Tasa Novo selo Rok</p>
                <p>3.Fasung Vratišinec</p>
                <p>4.Dijaden Šenkovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <p>Odigrana je revijalna malonogometna utakmica žena u kojoj su se sastale Nk Zagreb i Čakovec</p>
            </Timeline.Item>
        </Timeline>
    )
};

export const getTournamentHistoryContentDe = () => {
    return(
        <Timeline mode="alternate">
            {/*venera 08*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 08"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 20 Seniorenteams teilgenommen</h3>
                <p>1.Caffe bar Arcus</p>
                <p>2."Start Auto škola" Mala Subotica</p>
                <p>3.Onix bar Mursko Središće</p>
                <p>4.Crvena ruža Bednja</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 6 Veteranenteams teilgenommen</h3>
                <p>1.Bratić-Viher-Vidra</p>
                <p>2.Elita Merkur</p>
                <p>3.Beauty centar Ljupka</p>
                <p>4.SA-RA Pribislavec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 8 Pionirenteams teilgenommen</h3>
                <p>1.Bratić-Viher-Vidra</p>
                <p>2.Dream team</p>
                <p>3.C-Lab</p>
                <p>4.Vatreni</p>
            </Timeline.Item>
            {/*venera 07*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 07"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 24 Seniorenteams teilgenommen</h3>
                <p>1.Žulić graditeljstvo Domino bar</p>
                <p>2.Caffe bar Arcus</p>
                <p>3.Bar Torino Maribor</p>
                <p>4.Crvena ruža Bednja</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 8 Veteranenteams teilgenommen</h3>
                <p>1.Proventus</p>
                <p>2.Kos transporti</p>
                <p>3.Međimurje PMP</p>
                <p>4.Caffe bar Picadili</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 8 Pionirenteams teilgenommen</h3>
                <p>1.Proventus</p>
                <p>2.Nk Međimurje</p>
                <p>3.Nk Međimurec</p>
                <p>4.Crveni Vragovi</p>
            </Timeline.Item>
            {/*venera 06*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 06"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 25 Seniorenteams teilgenommen</h3>
                <p>1.Proming</p>
                <p>2.Eurotransporti Kostanjevec</p>
                <p>3.Onix Bar</p>
                <p>4.Crvena ruža Bednja</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 8 Veteranenteams teilgenommen</h3>
                <p>1.Međimurje PMP	</p>
                <p>2.Limarija Vinković</p>
                <p>3."SA -RA" Pribislavec</p>
                <p>4.Elita Merkur</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 16 Pionirenteams teilgenommen</h3>
                <p>1.Vihor Strahoninec</p>
                <p>2.Loka Goričan</p>
                <p>3.Pokemoni Strahoninec</p>
                <p>4.Vulička</p>
            </Timeline.Item>
            {/*venera 05*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 05"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 29 Seniorenteams teilgenommen</h3>
                <p>1."Bar Torino" Maribor"</p>
                <p>2.Pilana Posavec Lopatinec</p>
                <p>3."Start Auto škola" Mala Subotica</p>
                <p>4.Knegra</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 8 Veteranenteams teilgenommen</h3>
                <p>1."SA -RA" Pribislavec</p>
                <p>2."Međimurje PMP"</p>
                <p>3.Caffe Bar Palada Varaždin</p>
                <p>4.Vodači Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 16 Pionirenteams teilgenommen</h3>
                <p>1.Mega - Ing Čakovec</p>
                <p>2.Nk Sloga Čakovec</p>
                <p>3.Pokemoni Strahoninec</p>
                <p>4.Nk Zasadbreg</p>
            </Timeline.Item>
            {/*venera 04*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 04"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 26 Seniorenteams teilgenommen</h3>
                <p>1.Bar "Torino" Maribor</p>
                <p>2."Eurotransporti Kostanjevec"</p>
                <p>3."Start Auto škola" Mala Subotica</p>
                <p>4."Autoimpex" Pribislavec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 13 Veteranenteams teilgenommen</h3>
                <p>1."SA -RA" Pribislavec</p>
                <p>2."Međimurje PMP"</p>
                <p>3.Bar "Torino" Maribor</p>
                <p>4."Zalogajnica Šanjek" Varaždin</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 18 Pionirenteams teilgenommen</h3>
                <p>1."Proventus Varteks" Varaždin</p>
                <p>2."Cafe bar Centar" Gornji Mihaljevec</p>
                <p>3."Automikulaj" Krištanovec</p>
                <p>4."Krojači" Varaždin</p>
            </Timeline.Item>
            {/*venera 03*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 03"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 26 Seniorenteams teilgenommen</h3>
                <p>1.Katex & biro plus</p>
                <p>2.Knjigovodstvo Cimerman</p>
                <p>3.Promming</p>
                <p>4.Toyota Šavora</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 16 Veteranenteams teilgenommen</h3>
                <p>1.Zalogajnica Šanjek</p>
                <p>2.Radnička ulica</p>
                <p>3.Elita Merkur osiguranje</p>
                <p>4.Međimurje Pmp</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 13 Pionirenteams teilgenommen</h3>
                <p>1.Tom transporti</p>
                <p>2.Centar Vinković</p>
                <p>3.Nk Krištanovec</p>
                <p>4.Nk Venera</p>
            </Timeline.Item>
            {/*venera 02*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 02"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 26 Seniorenteams teilgenommen</h3>
                <p>1.Centrometal Macinec</p>
                <p>2.Auto škola Start Mala Subotica</p>
                <p>3.Katex and Plus Čakovec</p>
                <p>4.KMB Uno Marketing Ludbreg</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 10 Veteranenteams teilgenommen</h3>
                <p>1.Elita Merkur Čakovec</p>
                <p>2.Caffe bar M Varaždin</p>
                <p>3.Radnička ulica Lopatinec</p>
                <p>4.Udruga trgovca Međimurja Strahoninec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 2 Frauenmannschaften teilgenommen</h3>
                <p>1.ŽNK Mali Mihaljevec</p>
                <p>2.Fotex Nedeljanec</p>
            </Timeline.Item>
            {/*venera 01*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 01"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 22 Seniorenteams teilgenommen</h3>
                <p>1.Unipot Fulda Čakovec</p>
                <p>2.Fotex Nedeljanec</p>
                <p>3.Euroherc Najmarket Čakovec</p>
                <p>4.Patafta i sinovi Strahoninec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 8 Veteranenteams teilgenommen</h3>
                <p>1.Dib Međimurka Čakovec</p>
                <p>2.Elita Merkur Čakovec</p>
                <p>3.Fotex Nedeljanec</p>
                <p>4.Nk Strahoninec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 8 Frauenmannschaften teilgenommen</h3>
                <p>1.Fotex Nedeljanec</p>
                <p>2.Bad Blue Girls Koprivnica</p>
                <p>3.Mali Mihaljevec</p>
                <p>4.Lovorke Cirkovljan</p>
            </Timeline.Item>
            {/*venera 00*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 00"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 22 Seniorenteams teilgenommen</h3>
                <p>1.San Marco Čakovec</p>
                <p>2.Auto škola Start Mala Subotica</p>
                <p>3.Unipot</p>
                <p>4.Granobel</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 10 Veteranenteams teilgenommen</h3>
                <p>1.Tif</p>
                <p>2.Korni</p>
                <p>3.Radnička ulica Lopatinec</p>
                <p>4.Iver Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 5 Frauenmannschaften teilgenommen</h3>
                <p>1.Fotex Nedeljanec</p>
                <p>2.Tigrice Štrigova</p>
                <p>3.Mali Mihaljevec</p>
                <p>4.Oaza Mladost Selnica</p>
            </Timeline.Item>
            {/*venera 99*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 99"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 24 Seniorenteams teilgenommen</h3>
                <p>1.Orkan Gradvez Ljubljana</p>
                <p>2.Royal Barflay Čakovec</p>
                <p>3.Euro Unit Slakovec</p>
                <p>4.Motoreni Raidersi Nedelišće</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 12 Veteranenteams teilgenommen</h3>
                <p>1.Panex servis Čakovec</p>
                <p>2.IPC Čakovec</p>
                <p>3.Rdnička ulica Lopatinec</p>
                <p>4.Pedagoška Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 9 Frauenmannschaften teilgenommen</h3>
                <p>1.Fotex Nedeljanec</p>
                <p>2.Mali Mihaljevec</p>
                <p>3.Pande Mursko Središće</p>
                <p>4.Vidovčice Donji vidovec</p>
            </Timeline.Item>
            {/*venera 98*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 98"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 26 Seniorenteams teilgenommen</h3>
                <p>1.Adrenalin Ljubljana</p>
                <p>2.Moharić Comerce</p>
                <p>3.Bufet Zagorska klet trsek Varaždin</p>
                <p>4.Auto škola Start Mala Subotica</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 12 Veteranenteams teilgenommen</h3>
                <p>1.Venera Sveti Juraj na Bregu</p>
                <p>2.Napredak Gornji Kraljevec</p>
                <p>3.Auto škola Start Mala Subotica</p>
                <p>4.Elektrocentar Korunić Šenkovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 8 Frauenmannschaften teilgenommen</h3>
                <p>1.Crtorad Čakovec</p>
                <p>2.Tigrice Štrigova</p>
                <p>3.Fotex Nedeljanec</p>
                <p>4.Lovorke Cirkovljan</p>
            </Timeline.Item>
            {/*venera 97*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 97"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 25 Seniorenteams teilgenommen</h3>
                <p>1.Elektrocentar Korunić Šenkovec</p>
                <p>2.Seoski turizam Veselko Vugrišinec</p>
                <p>3.Radnička ulica Lopatinec</p>
                <p>4.Reidersi Nedelišće</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 8 Veteranenteams teilgenommen</h3>
                <p>1.Venera Sveti Juraj na Bregu</p>
                <p>2.Iver Čakovec</p>
                <p>3.GMC Smrtić Nedelišće</p>
                <p>4.Auto škola Start Mala Subotica</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 8 Frauenmannschaften teilgenommen</h3>
                <p>1.Tigrice Štrigova</p>
                <p>2.Fotex Nedeljanec</p>
                <p>3.Lovorke Cirkovljan</p>
                <p>4.Jelen Čakovec</p>
            </Timeline.Item>
            {/*venera 96*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 96"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 24 Seniorenteams teilgenommen</h3>
                <p>1.Bufet Zagorska klet trsek Varaždin</p>
                <p>2.Klesarska radnja Bratković Mihovljan</p>
                <p>3.Bistro ABI Zasadbreg</p>
                <p>4.Potrti kotač Jurovčak</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 7 Veteranenteams teilgenommen</h3>
                <p>1.Tesarska radnja Posavec Lopatinec</p>
                <p>2.Venera Sveti Juraj na Bregu</p>
                <p>3.Pedagoška Čakovec</p>
                <p>4.Iver Čakovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 6 Frauenmannschaften teilgenommen</h3>
                <p>1.Radio Venera Sveti Juraj na Bregu	</p>
                <p>2.Market Iva Vučetinec</p>
                <p>3.Bistro ABI Zasadbreg</p>
                <p>4.Omladinac Novo selo Rok</p>
            </Timeline.Item>
            {/*venera 95*/}
            <Timeline.Item position="left" color="green"><h2>"Venera 95"</h2></Timeline.Item>
            <Timeline.Item position="left" color="red">
                <h3>Es haben 28 Seniorenteams teilgenommen</h3>
                <p>1.Sneck bar Martin Nedelišće</p>
                <p>2.Bufet Zagorska klet trsek Varaždin</p>
                <p>3.Potrti kotač Jurovčak</p>
                <p>4.Reidersi Nedelišće</p>
            </Timeline.Item>
            <Timeline.Item position="left" color="red">
                <p>Es wurde ein kleines Frauenfußpalmatch gespielt zwischen Frizerski salon Anđela Pleškovec und Trgovina Jambrović Lopatinec</p>
            </Timeline.Item>
            {/*venera 94*/}
            <Timeline.Item position="right" color="green"><h2>"Venera 94"</h2></Timeline.Item>
            <Timeline.Item position="right" color="red">
                <h3>Es haben 21 Seniorenteams teilgenommen</h3>
                <p>1.Bistro Jocker Mursko Središće</p>
                <p>2.Tasa Novo selo Rok</p>
                <p>3.Fasung Vratišinec</p>
                <p>4.Dijaden Šenkovec</p>
            </Timeline.Item>
            <Timeline.Item position="right" color="red">
                <p>Es wurde ein kleines Frauenfußpalmatch gespielt zwischen Nk Zagreb und Čakovec</p>
            </Timeline.Item>
        </Timeline>
    )
};