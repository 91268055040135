import React from 'react';
import {Icon} from 'antd';

export const getFooterContentTitleHr = () => {
    return (<h1>UGOSTITELJSKI OBRT "VENERA" • 0IB 33580963400</h1>);
};

export const getFooterContentTitleDe = () => {
    return (<h1>GASTGEWERBE "VENERA" • Persönliche Identifikationsnummer 33580963400</h1>);
};

export const getFooterContentWorkingHoursHr = () => {
    return (
        <div>
            <ul>
                <li><strong>LJETNO RADNO VRIJEME:</strong></li>
                <li>NED-ČET 8:00-23:00</li>
                <li>PET-SUB 8:00-24:00</li>
            </ul>
            <ul>
                <li><strong>ZIMSKO RADNO VRIJEME:</strong></li>
                <li>NED-ČET 8:00-22:00</li>
                <li>PET-SUB 8:00-24:00</li>
            </ul>
        </div>
    )
};

export const getFooterContentKitchenWorkingHoursHr = () => {
    return (
        <ul>
            <li><strong>RADNO VRIJEME KUHINJE:</strong></li>
            <li>NED-PET 15:00-22:00</li>
            <li>SUB 13:00-22:00</li>
        </ul>
    )
};

export const getFooterContentDeliveryWorkingHoursHr = () => {
    return (
        <ul>
            <li><strong>DOSTAVA:</strong></li>
            <li>NED-ČET 15:00-21:00</li>
            <li>PET 15:00-22:00</li>
            <li>SUB 13:00-22:00</li>
        </ul>
    )
};

export const getFooterContentWorkingHoursDe = () => {
    return (
        <div>
            <ul>
                <li><strong>ÖFFNUNGSZEITEN IN DER SOMMERZEIT:</strong></li>
                <li>SO-DO 8:00-23:00</li>
                <li>FR-SA 8:00-24:00</li>
            </ul>
            <ul>
                <li><strong>ÖFFNUNGSZEITEN IN DER WINTERZEIT:</strong></li>
                <li>SO-DO 8:00-22:00</li>
                <li>FR-SA 8:00-24:00</li>
            </ul>
        </div>
    )
};

export const getFooterContentKitchenWorkingHoursDe = () => {
    return (
        <ul>
            <li><strong>ÖFFNUNGSZEITEN DER KÜCHE:</strong></li>
            <li>SO-FR 15:00-22:00</li>
            <li>SA 13:00-22:00</li>
        </ul>
    )
};

export const getFooterContentDeliveryWorkingHoursDe = () => {
    return (
        <ul>
            <li><strong>LIEFERUNG:</strong></li>
            <li>SO-DO 15:00-21:00</li>
            <li>FR 15:00-22:00</li>
            <li>SA 13:00-22:00</li>
        </ul>
    )
};

export const getFooterContentAddress = () => {
    return (
        <ul>
            <li>PIZZERIA "VENERA"</li>
            <li><Icon type="environment" /> <a target="_blank" href="https://www.google.hr/maps/place/Venera/@46.4497372,16.3661367,15z/data=!4m5!3m4!1s0x0:0x87deb85b01d17347!8m2!3d46.4497372!4d16.3661367">PLEŠKOVEC 63B</a></li>
        </ul>
    )
};

export const getFooterContentContact = () => {
    return (
        <ul>
            <li><Icon type="phone" /> <a href="tel:040-855-999">040-855-999</a></li>
            <li><Icon type="mail" /> <a href="mailto:sandra.vukovic12@gmail.com">sandra.vukovic12@gmail.com</a></li>
        </ul>
    )
};

export const getFooterContentSocialMediaHr = () => {
    return (
        <ul>
            <li><strong>PRATITE NAS NA:</strong></li>
            <li><a href="https://www.facebook.com/venera.pizzerija/posts" target="_blank"><Icon type="facebook" theme="filled" style={{ fontSize: '36px', color: '#BF9C8F' }} /></a></li>
            <li><a href="https://www.instagram.com/pizzeriavenera/" target="_blank"><Icon type="instagram" theme="filled" style={{ fontSize: '36px', color: '#BF9C8F' }} /></a></li>
        </ul>
    )
};

export const getFooterContentSocialMediaDe = () => {
    return (
        <ul>
            <li><strong>FOLGEN SIE UNS AUF:</strong></li>
            <li><a href="https://www.facebook.com/venera.pizzerija/posts" target="_blank"><Icon type="facebook" theme="filled" style={{ fontSize: '36px', color: '#BF9C8F' }} /></a></li>
            <li><a href="https://www.instagram.com/pizzeriavenera/" target="_blank"><Icon type="instagram" theme="filled" style={{ fontSize: '36px', color: '#BF9C8F' }} /></a></li>
        </ul>
    )
};

