import * as actionTypes from '../actions/actionsTypes';

const initialState = {
    posts: [],
    error: false
};

function facebookPageData(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_FACEBOOK_PAGE_DATA:
            return Object.assign({}, state, {
                posts: action.payload
            });
        case actionTypes.REMOVE_FACEBOOK_PAGE_DATA:
            return Object.assign({}, state, {
                posts: action.payload
            });
        case actionTypes.FETCH_FACEBOOK_PAGE_DATA_FAILED:
            return Object.assign({}, state, {
                error: action.payload
            });
        default:
            return state;
    }
}

export default facebookPageData;