import * as actionTypes from '../actions/actionsTypes';

const lan = localStorage.getItem('language') ? localStorage.getItem('language') : 'hr';

const initialState = {
    language: lan
};

function language(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_LANGUAGE:
            return Object.assign({}, state, {
                language: action.payload
            });
        default:
            return state;
    }
}

export default language;