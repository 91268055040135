import React, { Component } from 'react';
import { Radio } from 'antd';
import { setLanguage } from "../../actions/language";
import {connect} from "react-redux";


export class LanguageChooser extends Component {

    onChange = (e) => {
        this.props.setLanguage(e.target.value)
    };

    render() {
        return (
            <div className="language">
                <Radio.Group defaultValue={this.props.language} size="small" onChange={(e) => this.onChange(e)}>
                    <Radio.Button value="hr">HR</Radio.Button>
                    <Radio.Button value="de">DE</Radio.Button>
                </Radio.Group>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language
    };
}

export default connect(
    mapStateToProps,
    { setLanguage }
)(LanguageChooser);