import * as actionTypes from '../actions/actionsTypes';
import * as it from "../constants/itemType";

const initialState = {
    categories: [],
    selectedItemType: it.MENU,
    selectedCategory: '',
    searchTerm: '',
    startIndex: 0,
    endIndex: 10,
    hasMore: true,
    loading: false,
    length: 0,
    items: [],
    topOfferItems: [],
    topOfferItemIds: [],
    error: false
};

function priceListData(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_PRICE_LIST_DATA:
            return Object.assign({}, state, {
                items: action.payload
            });
        case actionTypes.ADD_FETCH_PRICE_LIST_DATA:
            return Object.assign({}, state, {
                items: state.items.concat(action.payload)
            });
        case actionTypes.SET_ITEM_TYPE:
            return Object.assign({}, state, {
                selectedItemType: action.payload
            });
        case actionTypes.SET_CATEGORY:
            return Object.assign({}, state, {
                selectedCategory: action.payload
            });
        case actionTypes.FETCH_TOP_OFFER_DATA:
            return Object.assign({}, state, {
                topOfferItems: action.payload
            });
        case actionTypes.SET_TOP_OFFER_ITEM_IDS:
            return Object.assign({}, state, {
                topOfferItemIds: action.payload
            });
        case actionTypes.SET_SEARCH_TERM:
            return Object.assign({}, state, {
                searchTerm: action.payload
            });
        case actionTypes.SET_START_INDEX:
            return Object.assign({}, state, {
                startIndex: action.payload
            });
        case actionTypes.SET_END_INDEX:
            return Object.assign({}, state, {
                endIndex: action.payload
            });
        case actionTypes.SET_HAS_MORE:
            return Object.assign({}, state, {
                hasMore: action.payload
            });
        case actionTypes.SET_LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });
        case actionTypes.SET_DATA_LENGTH:
            return Object.assign({}, state, {
                length: action.payload
            });
        case actionTypes.REMOVE_PRICE_LIST_DATA:
            return Object.assign({}, state, {
                items: action.payload
            });
        case actionTypes.FETCH_PRICE_LIST_DATA_FAILED:
            return Object.assign({}, state, {
                error: action.payload
            });
        case actionTypes.FETCH_CATEGORIES:
            return Object.assign({}, state, {
                categories: action.payload
            });
        case actionTypes.REMOVE_CATEGORIES:
            return Object.assign({}, state, {
                categories: action.payload
            });
        case actionTypes.FETCH_CATEGORIES_FAILED:
            return Object.assign({}, state, {
                error: action.payload
            });
        default:
            return state;
    }
}

export default priceListData;