import React from 'react';
import {Icon} from "antd";

export const getBenefitsContentHr = () => {
    return (
        <div className={"benefits-widget"}>
            <h1>Vaše pogodnosti u Pizzeriji Venera</h1>
            <ul>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Besplatan WiFi</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Dječje igralište</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Dječji kutak</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Jukebox</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Klađenje uživo (GERMANIA)</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Pikado</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Gledanje sportskih događaja uživo na TV-u</span>
                </li>
            </ul>
        </div>
    );
};

export const getBenefitsContentDe = () => {
    return (
        <div className={"benefits-widget"}>
            <h1>Ihre Vorteile in der Pizzeria Venera</h1>
            <ul>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Gratis WLAN</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Kinderspielplatz</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Kinderecke</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Music Box</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Live-Wetten (GERMANIA)</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Darts</span>
                </li>
                <li>
                    <span className={"benefit-icon"}><Icon type="check" /></span>
                    <span className={"benefit-text"}>Schauen vom Live-Sportveranstaltungen auf TV</span>
                </li>
            </ul>
        </div>
    );
};