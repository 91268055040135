import * as actionTypes from './actionsTypes';

export const setLanguage = (language) => {
    return dispatch => {
        localStorage.setItem('language', language);
        dispatch({
            type: actionTypes.SET_LANGUAGE,
            payload: language
        });
    };
};