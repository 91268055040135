import React, { Component } from 'react';
import {connect} from "react-redux";
import {getContent} from '../../../content/contentBasedOnLanguage';
import * as poc from '../../../constants/peaceOfContent'

export class FooterContent extends Component {

    render() {
        return (
            <div className="venera-footer-content">
                {getContent(this.props.language, poc.FOOTER_CONTENT_TITLE)}
                {getContent(this.props.language, poc.FOOTER_CONTENT_WORKING_HOURS)}
                {getContent(this.props.language, poc.FOOTER_CONTENT_KITCHEN_WORKING_HOURS)}
                {getContent(this.props.language, poc.FOOTER_CONTENT_DELIVERY_WORKING_HOURS)}
                {getContent(this.props.language, poc.FOOTER_CONTENT_ADDRESS)}
                {getContent(this.props.language, poc.FOOTER_CONTENT_CONTACT)}
                {getContent(this.props.language, poc.FOOTER_CONTENT_SOCIAL_MEDIA)}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        language: state.language.language
    };
}

export default connect(
    mapStateToProps,
    null
)(FooterContent);