// navigation
export const NAV_HOME = 'NAV_HOME';
export const NAV_ABOUT_US = 'NAV_ABOUT_US';
export const NAV_PIZZERIA = 'NAV_PIZZERIA';
export const NAV_IMAGE_GALLERY = 'NAV_IMAGE_GALLERY';
export const NAV_TOURNAMENT_HISTORY = 'NAV_TOURNAMENT_HISTORY';
export const NAV_TITLE = 'NAV_TITLE';

// page content
// home
export const HOME_HEAD = 'HOME_HEAD';
export const HOME_TITLE = 'HOME_TITLE';
export const HOME = 'HOME';
export const HOME_POSTS = 'HOME_POSTS';
export const HOME_SEE_ALL_POSTS = 'HOME_SEE_ALL_POSTS';
export const BENEFITS_WIDGET = 'BENEFITS_WIDGET';
export const SPOTLIGHT_WIDGET = 'SPOTLIGHT_WIDGET';
export const TOP_OFFER = 'TOP_OFFER';
// about us
export const ABOUT_US_HEAD = 'ABOUT_US_HEAD';
export const ABOUT_US_TITLE = 'ABOUT_US_TITLE';
export const ABOUT_US = 'ABOUT_US';
// pizzeria
export const PIZZERIA_HEAD = 'PIZZERIA_HEAD';
export const PIZZERIA_TITLE = 'PIZZERIA_TITLE';
export const PIZZERIA_OFFER = 'PIZZERIA_OFFER';
export const MENU = 'MENU';
export const SEARCH_RESULTS_FOR_MENU = 'SEARCH_RESULTS_FOR_MENU';
export const DRINKS = 'DRINKS';
export const SEARCH_RESULTS_FOR_DRINKS = 'SEARCH_RESULTS_FOR_DRINKS';
export const SEARCH_RESULTS_FOR_PIZZERIA = 'SEARCH_RESULTS_FOR_PIZZERIA';
export const SEARCH_LABEL_FOR_MENU = 'SEARCH_LABEL_FOR_MENU';
export const SEARCH_LABEL_FOR_DRINKS = 'SEARCH_LABEL_FOR_DRINKS';
export const PIZZERIA = 'PIZZERIA';
export const ITEM_PRICE = 'ITEM_PRICE';
export const ITEM_OPTIONS = 'ITEM_OPTIONS';
export const SMALL = 'SMALL';
export const BIG = 'BIG';
export const JUMBO = 'JUMBO';
export const ITEM_CONTAINS = 'ITEM_CONTAINS';
export const YOU_MIGHT_ALSO_LIKE = 'YOU_MIGHT_ALSO_LIKE';
// image gallery
export const IMAGE_GALLERY_HEAD = 'IMAGE_GALLERY_HEAD';
export const IMAGE_GALLERY_TITLE = 'IMAGE_GALLERY_TITLE';
// tournament history
export const TOURNAMENT_HISTORY_HEAD = 'TOURNAMENT_HISTORY_HEAD';
export const TOURNAMENT_HISTORY_TITLE = 'TOURNAMENT_HISTORY_TITLE';
export const TOURNAMENT_HISTORY = 'TOURNAMENT_HISTORY';
// footer content
export const FOOTER_CONTENT_TITLE = 'FOOTER_CONTENT_TITLE';
export const FOOTER_CONTENT_WORKING_HOURS = 'FOOTER_CONTENT_WORKING_HOURS';
export const FOOTER_CONTENT_KITCHEN_WORKING_HOURS = 'FOOTER_CONTENT_KITCHEN_WORKING_HOURS';
export const FOOTER_CONTENT_DELIVERY_WORKING_HOURS = 'FOOTER_CONTENT_DELIVERY_WORKING_HOURS';
export const FOOTER_CONTENT_ADDRESS = 'FOOTER_CONTENT_ADDRESS';
export const FOOTER_CONTENT_CONTACT = 'FOOTER_CONTENT_CONTACT';
export const FOOTER_CONTENT_SOCIAL_MEDIA = 'FOOTER_CONTENT_SOCIAL_MEDIA';

