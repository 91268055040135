import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
        original: 'https://venera.com.hr/gal/photos/pizza_20.jpg'
    },
    {
        original: 'https://venera.com.hr/gal/photos/pizza_21.jpg'
    },
    {
        original: 'https://venera.com.hr/gal/photos/skringo_2.jpg'
    }
];

export const getSpotlightContentHr = () => {



    return(
        <div className={"spotlight-container"} style={{ padding: '30px' }}>
            <h1>Spotlight</h1>
            <ImageGallery autoPlay={true} showThumbnails={false} lazyLoad={true} renderItem={i => {
                return(<img className={"responsive"} alt={"ceca"} src={i.original}/>)
            }} items={images} />
        </div>
    )
};

export const getSpotlightContentDe = () => {
    return(
        <div className={"spotlight-container"} style={{ background: '#ECECEC', padding: '30px' }}>
            <h1>Spotlight</h1>
            <ImageGallery autoPlay={true} showThumbnails={false} lazyLoad={true} renderItem={i => {
                return(<img className={"responsive"} alt={"ceca"} src={i.original}/>)
            }} items={images} />
        </div>
    )
};