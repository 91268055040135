import React, { useEffect } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Navigation from "./components/UI/Navigation";
import './App.css';
import {BackTop} from 'antd';
import { Layout } from 'antd';
import FooterContent from "./components/UI/Footer/FooterContent";
import 'antd/dist/antd.less';
import './style/theme/venera-theme.scss';
import "react-image-lightbox/style.css";
import manifest from './app_info.json'
const { Header, Content, Footer } = Layout;

const asyncHome = asyncComponent(() => {
    return import('./components/Home')
});

const asyncAbout = asyncComponent(() => {
    return import('./components/About')
});

const asyncPizzeria = asyncComponent(() => {
    return import('./components/Pizzeria')
});

const asyncImageGallery = asyncComponent(() => {
    return import('./components/PhotoGallery')
});

const asyncTournamentHistory = asyncComponent(() => {
    return import('./components/TournamentHistory')
});

function App() {

    useEffect(() => {
        const appVersion = window.localStorage.getItem('app_version');

        if (appVersion !== manifest.version) {
            window.localStorage.setItem('app_version', manifest.version);
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
            console.log('Complete Cache Cleared');
        }
    });
    
  return (
      <Layout className="layout">
            <Header>
                <Navigation/>
            </Header>
            <Content className="page-content">
                <Switch>
                    <Route exact path="/" component={asyncHome}/>
                    <Route exact path="/about" component={asyncAbout}/>
                    <Route exact path="/pizzeria" component={asyncPizzeria}/>
                    <Route exact path="/image-gallery" component={asyncImageGallery}/>
                    <Route exact path="/tournament-history" component={asyncTournamentHistory}/>
                    <Redirect to="/"/>
                </Switch>
                <div>
                    <BackTop />
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                <FooterContent />
            </Footer>
      </Layout>
  );
}

export default App;
